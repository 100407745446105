import React, { useState, useEffect } from "react";
import Dashboard from "./layouts/Dashboard/Dashboard";
import { useAuthContext } from "@asgardeo/auth-react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import NotFound from "./components/NotFound";
import { authConfigs } from "./Configs";
import NoAccess from "./components/NoAccess";

const HomePage = (props) => {
  const {
    state,
    signIn,
    signOut,
    getBasicUserInfo,
    getIDToken,
    getDecodedIDToken,
    refreshAccessToken,
    getAccessToken,
    revokeAccessToken,
  } = useAuthContext();

  const [derivedAuthenticationState, setDerivedAuthenticationState] =
    useState(null);
  const [hasAuthenticationErrors, setHasAuthenticationErrors] = useState(false);
  const [hasLogoutFailureError, setHasLogoutFailureError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!state?.isAuthenticated) {
      return;
    }

    (async () => {
      const basicUserInfo = await getBasicUserInfo();
      const idToken = await getIDToken();
      const decodedIDToken = await getDecodedIDToken();

      const derivedState = {
        authenticateResponse: basicUserInfo,
        idToken: idToken.split("."),
        decodedIdTokenHeader: JSON.parse(atob(idToken.split(".")[0])),
        decodedIDTokenPayload: decodedIDToken,
      };

      setDerivedAuthenticationState(derivedState);
    })();
  }, [state.isAuthenticated]);

  useEffect(() => {
    if (state.isAuthenticated && !state.isLoading) {
      refreshAccessToken().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleLogin = () => {
    signIn().catch((e) => {
      console.error(e);
    });
  };

  const checkAuthorization = () => {
    if (state.isAuthenticated) {
      if (
        derivedAuthenticationState?.decodedIDTokenPayload["groups"].includes(
          authConfigs.appAdminRole
        )
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <>
      {state.isAuthenticated ? (
        <>{checkAuthorization() ? <Dashboard {...props} /> : <NoAccess />}</>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ paddingTop: "20%" }}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              height: "20%",
              justifyContent: "center",
              width: "60%",
              padding: "30px",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <img
                  align="center"
                  alt="logo"
                  width="120"
                  height="auto"
                  src="https://wso2.cachefly.net/wso2/sites/images/brand/downloads/wso2-logo.png"
                ></img>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 40 }}>
                <Typography variant="h3">Certification Admin Portal</Typography>
              </Grid>
              <Grid item xs={12}>
                {state.isLoading || isLoading ? (
                  <Button
                    variant="outlined"
                    disabled
                    size="large"
                    color="#ff7300"
                    style={{ fontSize: 13 }}
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="large"
                    color="#ff7300"
                    style={{ fontSize: 13 }}
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Login
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default HomePage;
